import * as React from 'react';
import Script from 'next/script';
import Box from '@mui/material/Box';

interface TrustpilotIF extends LivelyInterfaces.ReactChildren {
  isSmall?: boolean;
  color?: string
}

const Trustpilot: React.FC<TrustpilotIF> = ({
  children = 'Trustpilot',
  variant,
  isSmall = true,
  color
}) => {
  const trustboxRef = React.useRef(null);
  const [loaded, setLoaded] = React.useState(false);
  isSmall = variant?.includes('Footer') ? false : isSmall;
  const dataTemplateId: string = isSmall ? '5419b732fbfb950b10de65e5' : '53aa8807dec7e10d38f59f32';
  const dataStyleHeight: string = isSmall ? '24px' : '150px';
  const dataTheme: string = color === "black" ? "light" : 'dark';
  React.useEffect(() => {
    if (window.Trustpilot) window.Trustpilot.loadFromElement(trustboxRef.current);
    setTimeout(() => {
      setLoaded(true);
    }, 1_000);
  }, [loaded]);
  return (
    <>
      <Script
        id="trustpilot"
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      />

      <Box
        sx={{
          transition: '.3s ease-in-out',
          marginTop: 1,
          ...(loaded
            ? {
                opacity: 1
              }
            : { opacity: 0 }),
          ...(variant?.includes('Footer')
            ? { maxWidth: 160 }
            : {
                marginLeft: -2
              })
        }}
        ref={trustboxRef}
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id={dataTemplateId}
        data-businessunit-id="5f8603feaa18e10001a3c99c"
        data-style-height={dataStyleHeight}
        data-style-width="100%"
        data-theme={dataTheme}>
        <a href="https://www.trustpilot.com/review/livelyme.com" target="_blank" rel="noopener">
          {children}
        </a>
      </Box>
    </>
  );
};

export default Trustpilot;
